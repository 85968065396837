import { Intro, DataTable, Product, Text, Pill, PopOver } from "components/commons";
import { FleetContext, AnalyticsContext } from "contexts";
import { DateTime, Products, Page } from "enums";
import { useApi, useFilter, useMount, useModal } from "hooks";
import React, { useCallback, useMemo, useContext } from "react";
import { redemptionsColumns } from "./redemptions-columns";
import RedemptionsFilter from "./redemptions-filter";
import { redemptionsFilterState } from "./redemptions-filter.state";
import styles from "./redemptions.module.scss";
import {
  prettifyDispenseType,
  prettifyProduct,
  prettifyRedemptionStatus,
} from "utils/pretty.utils";
import { capitalize } from "utils/text.utils";
import locale from "localization";
import { formatAmount, formatVolume } from "utils";
import moment from "moment";
import { getRedemptions, generateReport } from "apis";
import RedemptionStatus from "enums/redemption-status";
import { useHistory } from "react-router-dom";
// import { updateRedemptionRemarks } from "apis/redemption.api";
// import { MoreVertOutlined } from "@material-ui/icons";
// import VoidTransactionModal from "./void-transaction-model";
import { SuccessModal } from "components/modals";

const RedemptionsModule = (props) => {
  const successModal = useModal();
  const { page } = useContext(AnalyticsContext);
  // const { user } = useContext(UserContext);
  const { fleet } = useContext(FleetContext);
  const { location } = useHistory();
  // const voidTransactionModal = useModal();
  // const confirmModal = useModal();

  const {
    modifyFilter,
    modifyFilters,
    filterState,
    requestState,
    clearFilter,
    filterCount,
    submitFilter,
    submittedFilter,
  } = useFilter(redemptionsFilterState(fleet.fleetId, location));

  const {
    request: getRedemptionRequest,
    loading: fetchingRedemption,
    result: getRedemptionResult = { data: [], count: 0 },
  } = useApi({
    api: getRedemptions,
    pageError: true,
  });

  const { request: generateReportRequest, loading: fetchingReport } = useApi({
    api: generateReport,
    pageError: true,
  });

  // const { request: voidRequest, loading: voidingRequest } = useApi({
  //   api: voidRedemption,
  //   pageError: false,
  //   modalError: true,
  // });

  // const updateRemarksApi = useApi({
  //   api: updateRedemptionRemarks,
  //   modalError: true,
  //   pageError: false,
  // });

  useMount(() => {
    fetchRedemption(requestState);
    page({
      name: Page.ViewRedemptions,
    });
  });

  // useEffect(() => {
  //   const { downloadUrl } = getGeneratedReport;
  //   if (downloadUrl) {
  //     window.open(downloadUrl, "_blank");
  //   }
  // }, [getGeneratedReport]);

  const fetchRedemption = useCallback(
    (rs) => {
      const s = submitFilter(rs);

      // Check if redemptionStation exists and if it has a value, if not, delete it
      if (s.redemptionStation && s.redemptionStation.value === null) {
        delete s.redemptionStation;
      }

      getRedemptionRequest({ ...s, productCodes: s.productCodes.join(",") });
    },
    [getRedemptionRequest, submitFilter]
  );

  // const onVoidTransactionCb = useCallback(
  //   async ({ redemptionItem, value }) => {
  //     await voidRequest({ redemptionId: redemptionItem.redemptionId, remarks: value });
  //     voidTransactionModal.close();
  //     fetchRedemption();

  //     confirmModal.show({
  //       title: locale.transactionVoided,
  //       content: (
  //         <locale.Populate
  //           text={locale.thisTransactionHasBeenVoided}
  //           items={[
  //             <b>{formatVolume(redemptionItem?.literVolume)}</b>,
  //             <b>{redemptionItem?.fleet?.businessName}</b>,
  //           ]}
  //         />
  //       ),
  //       loading: voidingRequest,
  //       redemptionItem,
  //     });
  //     setRemarks("");
  //   },
  //   [voidRequest, voidTransactionModal, fetchRedemption, voidingRequest, confirmModal]
  // );

  const preparedRedemptionData = useMemo(() => {
    const { redemptions } = getRedemptionResult;
    if (redemptions && redemptions.length > 0) {
      const preparedData = redemptions.map((d) => {
        const map = new Map();
        map.set("redemptionId", d.redemptionId);
        map.set(
          "redemptionStation",
          <div>
            <Text>{d.station.name}</Text>
            <Text className={styles.subBusinessId}>{d.station.businessName}</Text>
          </div>
        );
        map.set("driverId", d.driver?.driverLicenseId || <Text italic>{locale.any}</Text>);
        map.set(
          "driverName",
          d?.driver?.firstName ? (
            `${capitalize(d.driver?.firstName)} ${capitalize(d.driver?.lastName)}`
          ) : (
            <Text italic>{locale.any}</Text>
          )
        );
        map.set(
          "dispensedToPlateNumber",
          <div>
            <Text>{prettifyDispenseType(d.dispensedTo)}</Text>

            <Text className={styles.subBusinessId}>
              {d.vehicle?.plateNumber || <Text italic>{locale.any}</Text>}
            </Text>
          </div>
        );
        map.set(
          "redemptionDate",
          <div>
            <Text>{moment(d.redeemedAt).format(DateTime.A)}</Text>
            <Text>{moment(d.redeemedAt).format(DateTime.B)}</Text>
          </div>
        );
        map.set(
          "fuelCodeRedemptionDate",
          <div>
            <Text>{d.fuelCode}</Text>

            <Text className={styles.subBusinessId}> {moment(d.redeemedAt).format(DateTime.S)}</Text>
          </div>
        );
        map.set(
          "productVolume",
          <div>
            <Product
              grass={d.productCode === Products.Gas91}
              salsa={d.productCode === Products.Gas95}
              deepBlue={d.productCode === Products.Gas97}
              cheddar={d.productCode === Products.Diesel}
            >
              {prettifyProduct(d.productCode)}
            </Product>
            <Text className={styles.subBusinessId}> {formatVolume(d.literVolume)}</Text>
          </div>
        );
        map.set(
          "averageCreditPrice",
          <div className="nowrap">{`${formatAmount(d.averageCreditPrice)}/L`}</div>
        );
        map.set(
          "totalCreditPrice",
          <div className="nowrap">{`${formatAmount(d.totalCreditPrice)}/L`}</div>
        );
        map.set("pointsEarned", <div className="nowrap">{d.pointsEarned || "0"}</div>);
        map.set("pumpPrice", <div className="nowrap">{`${formatAmount(d.pumpPrice)}`}</div>);
        map.set("orNumber", d.orNumber);
        map.set(
          "status",
          <Pill
            grass={d.status === RedemptionStatus.Success}
            cement={d.status === RedemptionStatus.Voided}
          >
            {prettifyRedemptionStatus(d.status)}
          </Pill>
        );

        map.set(
          "remarks",
          d.remarks ? (
            <PopOver content={<div className={styles.remarks}>{d.remarks}</div>}>
              <div className="link">View</div>
            </PopOver>
          ) : (
            ""
          )
        );
        // const options = [];
        // if (
        //   d.status === RedemptionStatus.Success
        //   // &&
        //   // moment().format("MM YYYY") === moment(d.redeemedAt).format("MM YYYY")
        // ) {
        //   options.push({
        //     content: locale.voidTransaction,
        //     onClick: () => {
        //       setRemarks("");
        //       voidTransactionModal.show({
        //         redemptionItem: d,
        //         title: `${locale.voidTransaction}?`,
        //         description: (
        //           <Text align="center">
        //             <locale.Populate
        //               text={locale.voidingThisTransaction}
        //               items={[<b>{formatVolume(d?.literVolume)}</b>]}
        //             />
        //           </Text>
        //         ),
        //         label: locale.reason,
        //         actionText: locale.void,
        //         submit: (value) => {
        //           onVoidTransactionCb({
        //             redemptionItem: d,
        //             value,
        //           });
        //         },
        //       });
        //     },
        //   });
        // }

        // if (d.status === RedemptionStatus.Voided) {
        //   options.push({
        //     content: locale.editRemarks,
        //     onClick: () => {
        //       setRemarks(d.remarks);
        //       voidTransactionModal.show({
        //         redemptionItem: d,
        //         title: locale.editRemarks,
        //         description: null,
        //         label: `${locale.reason}/${locale.remarks}`,
        //         actionText: locale.save,
        //         submit: async (value) => {
        //           await updateRemarksApi.request({
        //             id: d.redemptionId,
        //             remarks: value,
        //           });
        //           voidTransactionModal.close();
        //           setRemarks("");
        //           fetchRedemption();
        //         },
        //       });
        //     },
        //   });
        // }

        // if (options.length) {
        //   map.set(
        //     "actions",
        //     <PopOverMenu options={options}>
        //       <MoreVertOutlined className={styles.icon} />
        //     </PopOverMenu>
        //   );
        // }

        return map;
      });
      return preparedData;
    }
    return [];
  }, [
    getRedemptionResult,
    // fetchRedemption,
    // onVoidTransactionCb,
    // updateRemarksApi,
    // voidTransactionModal,
  ]);

  const onDateRangeCb = useCallback(
    (value) => {
      const { startDate, endDate } = value;
      modifyFilters({ startDate, endDate, page: 1 });
    },
    [modifyFilters]
  );

  const onProductChangeCb = useCallback(
    (productCodes) => {
      modifyFilters({ productCodes, page: 1 });
    },
    [modifyFilters]
  );

  const onStationChangeCb = useCallback(
    (redemptionStation) => {
      modifyFilters({ redemptionStation, redemptionStationId: redemptionStation.value, page: 1 });
    },
    [modifyFilters]
  );

  const onDispenseChangeCb = useCallback(
    (value) => {
      modifyFilters({ dispensedTo: value.value, page: 1 });
    },
    [modifyFilters]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      const { requestState } = modifyFilters({ searchKey, page: 1, perPage: 10 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageCb = useCallback(
    (page) => {
      const { requestState } = modifyFilters({ page });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );

  const onChangePageSizeCb = useCallback(
    (perPage) => {
      const { requestState } = modifyFilters({ perPage, page: 1 });
      fetchRedemption(requestState);
    },
    [modifyFilters, fetchRedemption]
  );
  const onRedemptionStatusChangeCb = useCallback(
    (name, { value }) => {
      modifyFilters({ redemptionStatus: value, page: 1 });
      // fetchRedemption(requestState);
    },
    [modifyFilters]
  );

  const onDownloadFileCb = async () => {
    const { requestState } = modifyFilters({ reportType: "redemption" });

    // Exclude redemptionStation from the request if it has no value
    if (requestState.redemptionStation && requestState.redemptionStation.value === null) {
      delete requestState.redemptionStation;
    } else if (requestState.redemptionStation) {
      requestState.redemptionStationId = requestState.redemptionStation.value;
      delete requestState.redemptionStation;
    }

    delete requestState.perPage;
    delete requestState.page;

    const generateResult = await generateReportRequest(requestState);
    if (generateResult?.downloadUrl) {
      window.open(generateResult?.downloadUrl, "_blank");
    } else {
      successModal.show({
        title: locale.successTitle,
        content: locale.reportGenerated,
      });
    }
  };

  return (
    <div>
      <SuccessModal {...successModal} />
      <div>
        <Intro title={locale.redemptions} subtitle={locale.viewTrackRedemptions} />
      </div>
      <div className={styles.filters}>
        <RedemptionsFilter
          onRedemptionStatusChangeCb={onRedemptionStatusChangeCb}
          onProductChangeCb={onProductChangeCb}
          filterState={filterState}
          onDateRangeChange={onDateRangeCb}
          onStationChange={onStationChangeCb}
          onSearchChange={modifyFilter}
          onSearch={onSearchCb}
          onDispenseChange={onDispenseChangeCb}
          onDownloadFile={onDownloadFileCb}
          fetchingReport={fetchingReport}
          resetFilter={() => {
            modifyFilters({ submittedFilter });
          }}
          filterCount={filterCount}
          submitFilter={() => {
            const { requestState } = modifyFilters({ page: 1 });
            fetchRedemption(requestState);
          }}
          clearFilter={() => {
            const { requestState } = clearFilter();
            fetchRedemption(requestState);
          }}
        />
      </div>
      <div className={styles.table}>
        <DataTable
          loading={fetchingRedemption}
          page={filterState.page}
          pageSize={filterState.perPage}
          columns={redemptionsColumns}
          data={preparedRedemptionData}
          dataCount={getRedemptionResult.count}
          onChangePage={onChangePageCb}
          onChangePageSize={onChangePageSizeCb}
          onRedemptionStatusChangeCb={onRedemptionStatusChangeCb}
        />
      </div>
      {/* <VoidTransactionModal
        {...voidTransactionModal}
        remarks={remarks}
        onChangeRemarks={(v) => {
          setRemarks(v);
        }}
        loading={voidingRequest || updateRemarksApi.loading}
        onSubmit={voidTransactionModal.submit}
      /> */}
    </div>
  );
};

export default RedemptionsModule;

import { Autocomplete, DateRange, Field, SearchField, Button } from "components/commons";
import { CashInChannel, Event } from "enums";
import React, { useCallback } from "react";
import { prettifyCashInChannel } from "utils/pretty.utils";
import styles from "./cashin-history-filter.module.scss";
import locale from "localization";

const CashInHistoryFilter = ({
  filterState,
  onDateRangeChange,
  onChannelChange,
  onSearchChange,
  onSearch,
  onDownloadFile,
  fetchingReport,
}) => {
  const onDateRangeChangeCb = useCallback(
    (name, { value, dirty }) => {
      if (onDateRangeChange) return onDateRangeChange(value, dirty);
    },
    [onDateRangeChange]
  );

  const onChannelChangeCb = useCallback(
    (name, { value }) => {
      if (onChannelChange) return onChannelChange(value || "all");
    },
    [onChannelChange]
  );

  const onSearchChangeCb = useCallback(
    (name, { value }) => {
      if (onSearchChange) {
        return onSearchChange("searchKey", { value });
      }
    },
    [onSearchChange]
  );

  const onSearchCb = useCallback(
    (searchKey) => {
      if (onSearch) {
        return onSearch(searchKey);
      }
    },
    [onSearch]
  );

  return (
    <div className={styles.filter}>
      <Field className={styles.date} label={locale.date}>
        <DateRange
          value={{
            startDate: filterState.startDate,
            endDate: filterState.endDate,
          }}
          onChange={onDateRangeChangeCb}
        />
      </Field>
      <Field className={styles.status} label={locale.cashInChannel}>
        <Autocomplete
          name="cashinChannel"
          value={filterState.cashinChannel}
          options={[
            { label: locale.all, value: "all" },
            {
              label: prettifyCashInChannel(CashInChannel.Card),
              value: CashInChannel.Card,
            },
            {
              label: prettifyCashInChannel(CashInChannel.LocqRebate),
              value: CashInChannel.LocqRebate,
            },
            {
              label: prettifyCashInChannel(CashInChannel.OnlineBanking),
              value: CashInChannel.OnlineBanking,
            },
            {
              label: prettifyCashInChannel(CashInChannel.ManualPaymaya),
              value: CashInChannel.ManualPaymaya,
            },
            {
              label: prettifyCashInChannel(CashInChannel.ManualBankDeposit),
              value: CashInChannel.ManualBankDeposit,
            },
            {
              label: prettifyCashInChannel(CashInChannel.IntroductoryPromo),
              value: CashInChannel.IntroductoryPromo,
            },
            {
              label: prettifyCashInChannel(CashInChannel.WithholdingTaxRefund),
              value: CashInChannel.WithholdingTaxRefund,
            },
            {
              label: prettifyCashInChannel(CashInChannel.SeaoilReciprocity),
              value: CashInChannel.SeaoilReciprocity,
            },
            {
              label: prettifyCashInChannel(CashInChannel.OTC),
              value: CashInChannel.OTC,
            },
            {
              label: prettifyCashInChannel(CashInChannel.VIP),
              value: CashInChannel.VIP,
            },
            {
              label: prettifyCashInChannel(CashInChannel.ManualBankDepositSPI),
              value: CashInChannel.ManualBankDepositSPI,
            },
          ]}
          onChange={onChannelChangeCb}
        />
      </Field>
      <Field className={styles.search}>
        <SearchField
          restrict={false}
          placeholder={locale.cashInIdAndNumber}
          value={filterState.searchKey}
          onChange={onSearchChangeCb}
          onEnter={onSearchCb}
        />
      </Field>
      <Field className={styles.downloadButton}>
        <Button
          startIcon={<span className="icon-download" />}
          exportButton
          onClick={onDownloadFile}
          loading={fetchingReport}
          eventName={Event.DownloadCashInHistoryReport}
        >
          {locale.export}
        </Button>
      </Field>
    </div>
  );
};

export default CashInHistoryFilter;

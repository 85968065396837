import {
  Balance,
  CardType,
  CashInChannel,
  CashInMethod,
  CashInStatus,
  DispenseType,
  DriverStatus,
  FuelCodeType,
  FuelCodesStatus,
  FuelType,
  PriceMovementType,
  Products,
  ReportType,
  Role,
  UserStatus,
  VehicleStatus,
} from "enums";
import FleetCardStatus from "enums/fleet-card-status";
import PaymentStatus from "enums/payment-status";
import RedemptionStatus from "enums/redemption-status";
import locale from "localization";

export const prettifyDriverStatus = (driverStatus) => {
  return (
    {
      [DriverStatus.Active]: locale.active,
      [DriverStatus.Inactive]: locale.inactive,
    }[driverStatus] || ""
  );
};

export const prettifyVehicleStatus = (vehicleStatus) => {
  return (
    {
      [VehicleStatus.Active]: locale.active,
      [VehicleStatus.Inactive]: locale.inactive,
    }[vehicleStatus] || ""
  );
};
export const prettifyDispenseType = (dispenseType) => {
  return (
    {
      [DispenseType.Vehicle]: locale.vehicle,
      [DispenseType.DrumContainer]: locale.drumContainer,
    }[dispenseType] || ""
  );
};
export const prettifyCashInStatus = (cashInStatus) => {
  return (
    {
      [CashInStatus.Success]: locale.completed,
      [CashInStatus.Pending]: locale.pending,
      [CashInStatus.Failed]: locale.failed,
      [CashInStatus.Expired]: locale.expired,
      [CashInStatus.Voided]: locale.voided,
    }[cashInStatus] || ""
  );
};

export const prettifyProduct = (productCode) => {
  return (
    {
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Products.Diesel]: locale.diesel,
      [Products.Lubes]: locale.lubricants,
    }[productCode] || ""
  );
};

export const prettifyCashInChannel = (cashinChannel) => {
  return (
    {
      [CashInChannel.ManualPaymaya]: locale.manualPaymaya,
      [CashInChannel.ManualBankDeposit]: locale.manualBankDeposit,
      [CashInChannel.Card]: locale.card,
      [CashInChannel.OnlineBanking]: locale.onlineBank,
      [CashInChannel.LocqRebate]: locale.locqRebate,
      [CashInChannel.IntroductoryPromo]: locale.introductoryPromoRebate,
      [CashInChannel.WithholdingTaxRefund]: locale.withholdingTaxRefund,
      [CashInChannel.SeaoilReciprocity]: locale.seaoilReciprocity,
      [CashInChannel.OTC]: "OTC Bank",
      [CashInChannel.LocqpayDiscount]: "LOCQPay Discount",
      [CashInChannel.VIP]: locale.vipPointsRedemption,
      [CashInChannel.ManualBankDepositSPI]: locale.manualBankDepositSPI,
    }[cashinChannel] || ""
  );
};

export const prettifyFuelCodesStatus = (fuelCodesStatus) => {
  return (
    {
      [FuelCodesStatus.Active]: locale.active,
      [FuelCodesStatus.Scheduled]: locale.scheduled,
      [FuelCodesStatus.Expired]: locale.expired,
      [FuelCodesStatus.Deactivated]: locale.deactivated,
      [FuelCodesStatus.Redeemed]: locale.redeemed,
    }[fuelCodesStatus] || ""
  );
};

export const prettifyBalance = (type) => {
  return (
    {
      [Balance.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Balance.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Balance.Gas97]: locale.populate(locale.gasName, ["97"]),
      [Balance.Diesel]: locale.diesel,
      [Balance.Peso]: locale.peso,
    }[type] || ""
  );
};

export const prettifyFuelCodeType = (type) => {
  return (
    {
      [FuelCodeType.DriverSpecific]: locale.driverSpecific,
      [FuelCodeType.VehicleSpecific]: locale.vehicleSpecific,
      [FuelCodeType.DriverVehicleSpecific]: locale.driverVehicleSpecific,
      [FuelCodeType.AnyDriverAnyVehicle]: locale.anyDriverAnyVehicle,
    }[type] || ""
  );
};

export const prettifyCashInMethod = (type) => {
  return (
    {
      [CashInMethod.Card]: locale.card,
      [CashInMethod.OverTheCounter]: locale.overTheCounter,
      [CashInMethod.OnlineBanking]: locale.onlineBank,
      [CashInMethod.ManualBankDeposit]: locale.manualBankDeposit,
      [CashInMethod.VIPBizPoints]: locale.vipBizPoints,
    }[type] || ""
  );
};

export const prettifyCardType = (type) => {
  return (
    {
      [CardType.Mastercard]: locale.mastercard,
      [CardType.Visa]: locale.visa,
      [CardType.Jcb]: locale.jcb,
    }[type] || ""
  );
};

export const prettifyPriceMovementType = (type) => {
  return (
    {
      [PriceMovementType.Initial]: locale.initialPriceUponOnboarding,
      [PriceMovementType.Discount]: locale.discountUpdate,
      [PriceMovementType.Account]: locale.accountSpecificPriceMovement,
      [PriceMovementType.National]: locale.nationalPriceMovement,
      [PriceMovementType.ChangeRefS]: locale.changeRefS,
    }[type] || ""
  );
};

export const prettifyFuelType = (type) => {
  return (
    {
      [FuelType.Diesel]: locale.diesel,
      [FuelType.Gas]: locale.gasoline,
      [Products.Gas91]: locale.populate(locale.gasName, ["91"]),
      [Products.Gas95]: locale.populate(locale.gasName, ["95"]),
      [Products.Gas97]: locale.populate(locale.gasName, ["97"]),
    }[type] || ""
  );
};

export const prettifyRedemptionStatus = (redemptionStatus) => {
  return (
    {
      [RedemptionStatus.Success]: locale.success,
      [RedemptionStatus.Voided]: locale.voided,
    }[redemptionStatus] || ""
  );
};

export const prettifyPaymentStatus = (paymentStatus) => {
  return (
    {
      [PaymentStatus.Success]: locale.success,
      [PaymentStatus.Voided]: locale.voided,
    }[paymentStatus] || ""
  );
};

export const prettifyFleetCardStatus = (fleetStatus) => {
  return (
    {
      [FleetCardStatus.Active]: locale.active,
      [FleetCardStatus.Inactive]: locale.inactive,
      [FleetCardStatus.Terminated]: locale.terminated,
      [FleetCardStatus.Deactivated]: locale.inactive,
      [FleetCardStatus.Scheduled]: locale.scheduled,
      [FleetCardStatus.Redeemed]: locale.redeemed,
      [FleetCardStatus.Expired]: locale.expired,
    }[fleetStatus] || ""
  );
};

export const prettifyReportType = (type) => {
  return (
    {
      [ReportType.CashIn]: "Cash In",
      [ReportType.BuyFuel]: "Buy Fuel",
      [ReportType.Redemption]: "Redemption",
      [ReportType.RedemptionItem]: "Redemption Item",
      [ReportType.PricingHistory]: "Pricing History",
      [ReportType.FuelCode]: "Fuel Code",
      [ReportType.FuelCodes]: "Fuel Codes",
      [ReportType.Mops]: "Mops",
      [ReportType.VoucherClaim]: "Voucher Claim",
      [ReportType.NewCustomerVoucher]: "New Customer Voucher",
      [ReportType.FleetPrices]: "Fleet Prices",
      [ReportType.PriceBuildup]: "Price Buildup",
      [ReportType.FleetAccount]: "Fleet Account",
      [ReportType.StationAccount]: "Station Account",
      [ReportType.CustomerList]: "Customer List",
      [ReportType.VoucherCampaign]: "Voucher Campaign",
      [ReportType.DiscountCampaign]: "Discount Campaign",
      [ReportType.RfidTopup]: "Rfid Topup",
      [ReportType.Payment]: "Payment",
      [ReportType.KYCSubmission]: "KYC Submission",
      [ReportType.PriceMovement]: "Price Movement",
      [ReportType.SupplierWPP]: "Supplier WPP",
      [ReportType.FleetCard]: "Fleet Card",
      [ReportType.MerchantPayment]: "Merchant Payment",
    }[type] || ""
  );
};

export const prettifyUserStatus = (type) => {
  return (
    {
      [UserStatus.Active]: "Active",
      [UserStatus.Deactivated]: "Deactivated",
      [UserStatus.Deleted]: "Deleted",
    }[type] || ""
  );
};

export const prettifyRole = (role) => {
  return (
    {
      [Role.PortalAdmin]: locale.admin,
      [Role.Purchaser]: locale.purchaser,
      [Role.FleetManager]: locale.fleetManager,
      [Role.DataAnalyst]: locale.dataAnalyst,
      [Role.SeaoilAccountManager]: locale.seaoilAccountManager,
    }[role] || ""
  );
};

export const prettifyFeedback = (value) => {
  return (
    {
      [value]: locale[value],
    }[value] || ""
  );
};
